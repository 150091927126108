import { useState } from 'react';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { WORDPRESS_BASE_URL } from '@src/lib/constants';

import {
  CloseBannerButton,
  DesktopBannerText,
  FAQLink,
  MobileBannerText,
  PromoCode,
  PromoTab,
  StyledBanner,
  Tooltip,
} from './styles';

const PromotionalBanner = () => {
  const [isPromoBannerVisible, setIsPromoBannerVisible] = useState(true);
  const [isStickerVisible, setIsStickerVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const isDesktop = useMediaQuery(800);

  const copyPromoCode = () => {
    navigator.clipboard.writeText('BlastOFF15');
    setShowTooltip(true);

    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  const handleCloseBanner = () => {
    setIsPromoBannerVisible(false);
    setIsStickerVisible(true);
  };

  const handleStickerClick = () => {
    setIsPromoBannerVisible(true);
    setIsStickerVisible(false);
  };

  return (
    <>
      {isPromoBannerVisible && (
        <StyledBanner>
          <CloseBannerButton onClick={handleCloseBanner}>×</CloseBannerButton>
          {isDesktop ? (
            <DesktopBannerText>
              Save 15% on your catering orders with promo code{' '}
              <PromoCode onClick={copyPromoCode}>BlastOFF15 {showTooltip && <Tooltip>Copied!</Tooltip>} </PromoCode>.
              Don't miss this online exclusive summer offer!{' '}
              <FAQLink target={`_blank`} href={`${WORDPRESS_BASE_URL}/faqs`}>
                See our FAQs
              </FAQLink>{' '}
              for details.
            </DesktopBannerText>
          ) : (
            <MobileBannerText>
              Save 15% with promo code{' '}
              <PromoCode onClick={copyPromoCode}>BlastOFF15{showTooltip && <Tooltip>Copied!</Tooltip>}</PromoCode>.{' '}
              <FAQLink target={`_blank`} href={`${WORDPRESS_BASE_URL}/faqs`}>
                See our FAQs
              </FAQLink>
              .
            </MobileBannerText>
          )}
        </StyledBanner>
      )}
      {isStickerVisible && <PromoTab onClick={handleStickerClick}>Get 15% OFF</PromoTab>}
    </>
  );
};

export default PromotionalBanner;
